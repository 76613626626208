import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
	{
		path: '/portfolio',
		name: 'Portfolio',
		exact: true,
		component: () => import('./tipure/MyPortfolio.vue')
	},

	{
		path: '/empty',
		name: 'empty',
		component: () => import('./components/EmptyPage.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('./pages/Login.vue')
	},

	{
		path: '/portfolio',
		name: 'portfolio',
		component: () => import('./tipure/MyPortfolio.vue')
	},
	{
		path: '/formulation/:module/:account?',
		name: 'formulation',
		component: () => import('./tipure/formulation.vue')
	},
	{
		path: '/admin/modelManagement',
		name: 'Admin',
		component: () => import('./tipureAdmin/modelListView.vue')
	},

	{
		path: '/admin/newModel',
		name: 'newModel',
		component: () => import('./tipureAdmin/modelManager.vue')
	},

	{
		path: '/admin/addModel',
		name: 'addModel',
		component: () => import('./tipureAdmin/newCustomer.vue')
	},
	{
		path: '/admin/addUser',
		name: 'addUser',
		component: () => import('./tipureAdmin/newUser.vue')
	},
	{
		path: '/admin/listUser',
		name: 'listUser',
		component: () => import('./tipureAdmin/listUsers.vue')
	},
	{
		path: '/legal',
		name: 'legal',
		component: () => import('./tipure/tipureLegal.vue')
	},

	{
		path: '/userportfolio',
		name: 'User Portfolio',
		exact: true,
		component: () => import('./tipure/userModels.vue')
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

export default router;