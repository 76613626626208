/*
 Simple component to share state across components.

*/

import { reactive, readonly } from "vue";

const state = reactive({
  modelName: null
});

const setModel = function (name) {
  state.modelName = name;
}

export default { state: readonly(state), setModel };