import { reactive } from 'vue';

import * as msal from "@azure/msal-browser";
import EventBus from '../event-bus';

const auth = reactive({
    isAuthenticated: false,
    authResponse: null,
    msalInstance: null,
    loggerCallback(logLevel, message, containsPii) {
        console.log(logLevel);
        console.log(containsPii);
        console.log(message);
    },

    login() {

        const msalConfig = {
            auth: {
                clientId: process.env.VUE_APP_CLIENTID,
                authority: 'https://login.microsoftonline.com/' + process.env.VUE_APP_TENNAT_ID, //  68a7ff20-812f-4738-90ea-0b1051846f74'
                postLogoutRedirectUri: process.env.VUE_APP_LOGOUT_URL, //'https://contoso.com/homepage'
                scopes: ["user.read", process.env.VUE_APP_API_SCOPE]
            },
            system: {
                logger: new msal.Logger(
                    this.loggerCallback, {
                    level: msal.LogLevel.Verbose,
                    piiLoggingEnabled: false,
                    correlationId: 'TIPURE_WEB'
                }
                )
            }
        };
        console.log("Log in configtured");
        this.msalInstance = new msal.PublicClientApplication(msalConfig);

        console.log("instalnce");
        let loginResponse = this.msalInstance.loginPopup({
            scopes: ["user.read",process.env.VUE_APP_API_SCOPE],
            redirectUri: process.env.VUE_APP_AUTH_RETURN
        });

        loginResponse.then(result => {
            console.log("login response")
            console.log(result)
            this.authResponse = result;
            this.isAuthenticated = true;
            EventBus.emit('Authenticated');
        }, // shows "done!" after 1 second
            error => alert(error) // doesn't run
        );
    },

    getCachedToken() {
        return this.authResponse.accessToken;
    },

    async getAccessToken() {
        console.log("Requesting access token");
        const accessTokenRequest = {
            scopes: [process.env.VUE_APP_API_SCOPE],
            account: this.authResponse.account,
            redirectUri: process.env.VUE_APP_AUTH_RETURN
        };
        let loginResponse = null
        try {
            loginResponse = await this.msalInstance.acquireTokenSilent(accessTokenRequest);
        } catch (err) {

            loginResponse = await this.msalInstance.loginPopup(accessTokenRequest)
        }

        console.log("loginResponse");
        console.log(loginResponse);
        
        return loginResponse;
    },


    logOut() {
        this.msalInstance.logoutRedirect();
    },

    isAdminUser() {
        this.getAccessToken().then((tokenResponse) => {
            console.log("Checking admin")
            console.log(tokenResponse)

        })
    },

    getLoggedInUserName() {
        if (this.authResponse && this.authResponse.account) {
            return this.authResponse.account.name;
        }
        else return "";
    },

    getLoggedInUserId() {
        if (this.authResponse && this.authResponse.account) {
            return this.authResponse.account.username;
        }
        else return "";
    }


});

export default auth;

export const AuthPlugin = {
    install(app, options) {
        // Options - Need better use cases
        if (options['action'] == 'init') {
            app.config.globalProperties.$auth = auth;
        }
        app.config.globalProperties.$auth = auth;
    },
};
