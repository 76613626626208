<template>
    <div class="layout-topbar">
        <div class="grid">
            <div class="col-12 layout-topbar-link">
                <!--<img id="topbar-cobrand" src="assets/layout/images/cobrand-logo-white.svg" alt="co-brand icon" />
            -->
                <i class="pi pi-arrow-left"> </i>
                <!--<span @click="switchToCompanyWebSite()">Chemours.com</span>-->
                <a href="https://chemours.com" style="color: white" target="_blank"> Chemours.com</a>
            </div>
            <div class="col-6 brand">
                <img id="topbar-cobrand" src="assets/layout/images/cobrand-logo.svg" />
            </div>
            <div class="col-6 user">
                <div>{{ $auth.getLoggedInUserName() }}</div>
                <div class="sign-out">Not you? <a href="" @click="this.$auth.logOut()">Sign out</a></div>
            </div>
            <!--<div class="layout-topbar-title">
            <span>Application Title</span>
        </div>-->
        </div>
    </div>
</template>

<script>
export default {
    emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click', 'right-menubutton-click'],
    props: {
        topbarMenuActive: Boolean,
        activeTopbarItem: String,
        inlineUser: Boolean,
    },
    methods: {
        switchToCompanyWebSite() {
            window.location.href = 'https://chemours.com';
        },

        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        onRightMenuButtonClick(event) {
            this.$emit('right-menubutton-click', event);
        },
    },
};
</script>
<style lang='scss' scoped>
.layout-topbar {
    background: #efefef !important;
    padding: 0rem !important;
    height: 5rem;
    box-shadow: 0px 0px 0px 0px;
    .grid {
        .brand {
            text-align: start !important;
            padding-left: 6.5rem;
            height: 2.5rem;
            img {
                height: 2.5rem;
            }
        }
        .user {
            font-size: 90%;
            color: #0092bc;
            direction: rtl;
            padding-right: 2rem;
            span,
            div {
                font-weight: 600;
                display: block;
                a {
                    color: red;
                }
            }
        }

        .col-12 {
            align-content: flex-end;
            text-align: end;
        }
        .layout-topbar-link {
            background: #0093bc;
            float: right;
            position: relative;
            vertical-align: middle;
            color: #fff;
            font-size: 90%;
            font-weight: 600;
            height: 2.5rem;
            padding-right: 2rem;
        }
    }
}
</style>