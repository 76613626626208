import axios from 'axios'

export default class RequestService {

    raiseAccessRequest(name, email_id, comments) {
        let payload = { "Name": name, "eMail": email_id, "Message": comments }
        console.log("Raising access request")
        console.log(payload)
        return axios.post(process.env.VUE_APP_BACKEND_URL + '/accessRequest', payload).then(res => {
            console.log(res.data)
            return res.data
        });
    }

}