<template>
    <div class="landing-header grid">
        <!--<div class="title">Ti-Pure Formulator</div>-->
        <div class="col-3">
            <img id="logo" src="assets/layout/images/chem-icon-white.svg" alt="brand icon" height="35" />
        </div>
        <div class="col-6 app-title">
            Ti-Pure<sup><small>TM</small></sup> Formulator <small></small>
        </div>
        <div class="col-3">
            <img id="brand" src="assets/layout/images/cobrand-logo-white.svg" alt="co-brand icon" height="35" />
        </div>
    </div>
    <div>
        <div class="page-panel">
            <div class="grid">
                <div class="col-8 intro-content">
                    <!-- <iframe src="//www.youtube.com/embed/YE7VzlLtp-4" frameborder="0" allowfullscreen></iframe>-->
                    <div class="shadow-2">
                        <div class="">
                            <h3>
                                Welcome to the Ti-Pure<sup><small>TM</small></sup> Formulator
                            </h3>
                            <div>Leveraging Machine Learning and real-world expertise to accelerate formulation development</div>
                        </div>

                        <div class="container">
                            <svg class="machine" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 645 526">
                                <defs />
                                <g>
                                    <path
                                        x="-173,694"
                                        y="-173,694"
                                        class="large-shadow"
                                        d="M645 194v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L602 68l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L482 10h-21l-4 29c-10 1-19 3-28 6l-14-25 -19 8 7 28c-8 5-16 10-24 16l-23-17L341 68l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L645 194zM471 294c-61 0-110-49-110-110S411 74 471 74s110 49 110 110S532 294 471 294z"
                                    />
                                </g>
                                <g>
                                    <path
                                        x="-136,996"
                                        y="-136,996"
                                        class="medium-shadow"
                                        d="M402 400v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L352 323c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L402 400zM265 463c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C338 430 305 463 265 463z"
                                    />
                                </g>
                                <g>
                                    <path
                                        x="-100,136"
                                        y="-100,136"
                                        class="small-shadow"
                                        d="M210 246v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H100l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L10 225v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L210 246zM110 272c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S131 272 110 272z"
                                    />
                                </g>
                                <g>
                                    <path
                                        x="-100,136"
                                        y="-100,136"
                                        class="small"
                                        d="M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z"
                                    />
                                </g>
                                <g>
                                    <path
                                        x="-173,694"
                                        y="-173,694"
                                        class="large"
                                        d="M635 184v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L592 58l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L472 0h-21l-4 29c-10 1-19 3-28 6L405 9l-19 8 7 28c-8 5-16 10-24 16l-23-17L331 58l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L635 184zM461 284c-61 0-110-49-110-110S401 64 461 64s110 49 110 110S522 284 461 284z"
                                    />
                                </g>
                                <g>
                                    <path
                                        x="-136,996"
                                        y="-136,996"
                                        class="medium"
                                        d="M392 390v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L342 313c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L392 390zM255 453c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C328 420 295 453 255 453z"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <!--<div class="card">
                        Chemours is one of the world's largest manufacturers of titanium dioxide (TiO₂). The Ti-Pure™ brand delivers industry-leading innovation to address the growing worldwide demand for high-quality TiO₂ by developing brighter,
                        more efficient pigments for the coatings, plastics, and laminates markets. Chemours has been manufacturing Ti-Pure™ TiO₂ for nearly a century, using industry-leading processes, technology, and safety standards. The company's
                        global manufacturing capabilities and technical support enable reliable and consistent supply to customers around the world. Fostering innovation to improve product quality and customer experiences has always been critical to
                        the brand's success. Beyond the products in the Ti-Pure™ portfolio, Chemours understands the value of forming collaborative partnerships with its customers, their downstream businesses, co-suppliers, and consumers. These
                        partnerships enhance value chain efficiency, create better knowledge, and deliver products that improve the world.
                    </div>-->
                </div>
                <div class="col-4 register">
                    <!--<div><iframe src="https://previews.customer.envatousercontent.com/h264-video-previews/5006100.mp4" frameborder="0" allowfullscreen></iframe></div>
-->
                    <div class="card p-fluid">
                        <div class="p-field grid">
                            <label for="request" class="col-12 p-mb-6 p-md-6 p-mb-md-0"> Registered Users </label>
                            <div class="col-12 p-md-6">
                                <Button id="request" label="SIGN IN" icon="pi pi-check" @click="$auth.login()" />
                            </div>
                        </div>
                    </div>
                    <div v-show="!requestSubmitted" class="card p-fluid shadow-2">
                        <h6>New User?</h6>
                        <div class="p-field grid">
                            <label for="name3" class="col-2 p-mb-2 p-md-2 p-mb-md-0">Name<span class="mandatory">*</span></label>
                            <div class="col-10 p-md-10">
                                <span class="p-float-label">
                                    <InputText id="name" type="text" :required="true" v-model="requestorName" />
                                </span>
                            </div>
                        </div>
                        <div class="p-field grid">
                            <label for="email3" class="col-2 p-mb-2 p-md-2 p-mb-md-0">Email<span class="mandatory">*</span></label>
                            <div class="col-10 p-md-10">
                                <span class="p-float-label">
                                    <InputText id="email" type="text" :required="true" v-model="requestorEmail" />
                                </span>
                            </div>
                        </div>
                        <br />
                        <div class="p-field grid">
                            <!-- <label for="request" class="col-12 p-mb-12 p-md-12 p-mb-md-0"></label> -->
                            <div class="col-12 p-md-12">
                                <Button :disabled="!(requestorEmail && requestorName)" id="request" label="Request Access" icon="pi" @click="raiseAccessRequest" />
                            </div>
                        </div>
                    </div>
                    <div v-show="requestSubmitted" class="card p-fluid shadow-2">
                        <h6>Thank you for your interest. Our team will revert shortly.</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="landing-footer grid">
        <div class="title">Ti-Pure Formulator</div>
        <div class="col-3"></div>
        <div class="col-6 app-title"></div>
        <div class="col-3"></div>
    </div> -->
</template>

<script>
import EventBus from '../event-bus';
import RequestService from '../tipureServices/RequestService';

export default {
    components: {},
    data() {
        return {
            app_env: process.env.VUE_APP_ENV,
            checked: false,
            requestorEmail: null,
            requestorName: null,
            requestSubmitted: false,
        };
    },
    mailRequestService: null,
    created() {
        this.mailRequestService = new RequestService();
    },
    methods: {
        goDashboard() {
            this.$router.push({ path: '/portfolio' });
        },
        // CheckEntries(){
        //     if((this.requestorEmail != null) && (this.requestorName != null))
        //     return true
        //     else
        //     return false
        // },
        raiseAccessRequest() {
            this.mailRequestService.raiseAccessRequest(this.requestorName, this.requestorEmail, ' Access request for Ti-Pure Formulator').then((data) => {
                if (data['response_code'] == 200) {
                    this.requestSubmitted = true;
                    this.$toast.add({ severity: 'success', summary: 'Request submitted', detail: 'Your request submitted', life: 3000 });
                } else {
                    this.requestSubmitted = false;
                    this.$toast.add({ severity: 'Error', summary: 'Error reported', detail: 'Please try later', life: 3000 });
                }
            });
        },
    },
    mounted() {
        EventBus.on('Authenticated', () => {
            this.goDashboard();
        });
    },
};
</script>

<style lang="scss">
#logo {
    float: left;
}
#brand {
    float: right;
}

.landing-logo-container {
    widows: 100%;
}

.landing-header {
    background-color: #0092bc;
}

.landing-footer {
    background-color: #33a8c9;
}
.landing-header,
.landing-footer {
    padding: 0rem;
    margin: 0px;

    .col-6,
    .col-3 {
        padding: 1.2rem;
    }

    .app-title {
        color: #fff;
        align-content: center;
        text-align: center;
        font-weight: 400;
        font-size: 25px;
        sup {
            font-size: 8px;
        }
    }
}
.title-x {
    width: 100%;
    background-color: dimgrey;
    color: #cfcfcf;
    font-weight: 400;
    font-size: 150%;
}
iframe {
    width: 100%;
    height: 300px;
    align-content: center;
}

.page-panel {
    width: 100%;
    padding: 3rem 5rem 1rem 4rem;
    .intro-content {
        div,
        h2,
        h3,
        h4 {
            margin: 0 auto;
            text-align: center;
            justify-content: center;
        }
    }
}

.register {
    image {
        height: 10px;
    }
}

.intro-content {
    align-content: center;
}
.teaser-content {
    position: relative;
    width: 37%;
    margin: 0 auto;
    left: 0px;

    background: brown;
}

.container {
    widows: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.machine {
    width: 60vmin;
    fill: #0092bc;
    height: 60vh;
}

.small-shadow,
.medium-shadow,
.large-shadow {
    fill: rgba(0, 0, 0, 0.05);
}

.small {
    -webkit-animation: counter-rotation 7.5s infinite linear;
    -moz-animation: counter-rotation 7.5s infinite linear;
    -o-animation: counter-rotation 7.5s infinite linear;
    animation: counter-rotation 7.5s infinite linear;
    -webkit-transform-origin: 100.136px 225.345px;
    -ms-transform-origin: 100.136px 225.345px;
    transform-origin: 100.136px 225.345px;
    fill: #8ccee1;
}

.small-shadow {
    -webkit-animation: counter-rotation 7.5s infinite linear;
    -moz-animation: counter-rotation 7.5s infinite linear;
    -o-animation: counter-rotation 7.5s infinite linear;
    animation: counter-rotation 7.5s infinite linear;
    -webkit-transform-origin: 110.136px 235.345px;
    -ms-transform-origin: 110.136px 235.345px;
    transform-origin: 110.136px 235.345px;
}

.medium {
    -webkit-animation: rotation 11.25s infinite linear;
    -moz-animation: rotation 11.25s infinite linear;
    -o-animation: rotation 11.25s infinite linear;
    animation: rotation 11.25s infinite linear;
    -webkit-transform-origin: 254.675px 379.447px;
    -ms-transform-origin: 254.675px 379.447px;
    transform-origin: 254.675px 379.447px;
    fill: #33a8c9;
}

.medium-shadow {
    -webkit-animation: rotation 11.25s infinite linear;
    -moz-animation: rotation 11.5s infinite linear;
    -o-animation: rotation 11.25s infinite linear;
    animation: rotation 11.25s infinite linear;
    -webkit-transform-origin: 264.675px 389.447px;
    -ms-transform-origin: 264.675px 389.447px;
    transform-origin: 264.675px 389.447px;
}

.large {
    -webkit-animation: counter-rotation 15s infinite linear;
    -moz-animation: counter-rotation 15s infinite linear;
    -o-animation: counter-rotation 15s infinite linear;
    animation: counter-rotation 15s infinite linear;
    -webkit-transform-origin: 461.37px 173.694px;
    -ms-transform-origin: 461.37px 173.694px;
    transform-origin: 461.37px 173.694px;
}

.large-shadow {
    -webkit-animation: counter-rotation 15s infinite linear;
    -moz-animation: counter-rotation 15s infinite linear;
    -o-animation: counter-rotation 15s infinite linear;
    animation: counter-rotation 15s infinite linear;
    -webkit-transform-origin: 471.37px 183.694px;
    -ms-transform-origin: 471.37px 183.694px;
    transform-origin: 471.37px 183.694px;
}

.mandatory {
    color: #da0000;
    padding-left: 5px;
}
@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(359deg);
    }
}
@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(359deg);
    }
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@-webkit-keyframes counter-rotation {
    from {
        -webkit-transform: rotate(359deg);
    }
    to {
        -webkit-transform: rotate(0deg);
    }
}
@-moz-keyframes counter-rotation {
    from {
        -moz-transform: rotate(359deg);
    }
    to {
        -moz-transform: rotate(0deg);
    }
}
@-o-keyframes counter-rotation {
    from {
        -o-transform: rotate(359deg);
    }
    to {
        -o-transform: rotate(0deg);
    }
}
@keyframes counter-rotation {
    from {
        transform: rotate(359deg);
    }
    to {
        transform: rotate(0deg);
    }
}
</style>
